import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { useInfiniteScroll } from "src/hooks/useInfiniteScroll"

import ProductionImage from "./ProductionImage"
import ProductionVideo from "./ProductionVideo"

const Container = styled.div`
  padding: 0 5px;
  display: flex;
  @media (max-width: 900px) {
    display: block;
  }
`
const Column = styled.ul`
  flex-basis: calc(100% / 3);
  display: block;
  padding-left: 0;
  margin: 20px 0 0;
  list-style: none;
`
const Item = styled.li`
  width: 100%;
  margin-bottom: 0px;
  min-height: 150px;
  transition: height 0.2s ease-in-out;

  @media (max-width: 900px) {
    width: 100%;
  }
`

const PAGE_LENGTH = 20

const Gallery = ({ slug, productions }) => {
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)
  const [items, setItems] = useState(productions.slice(0, page * PAGE_LENGTH))
  const listRef = useRef()

  useEffect(() => {
    if (page * PAGE_LENGTH < productions.length) {
      setItems(productions.slice(0, page * PAGE_LENGTH))
    } else {
      setItems(productions)
      setHasMore(false)
    }
  }, [page, productions])

  useInfiniteScroll(
    listRef,
    () => false,
    () => {
      // if we have more items to load
      if (hasMore) {
        const nextPage = page + 1
        // display loading
        setPage(nextPage)
      }
    }
  )

  const rows = [[], [], []]
  items.forEach((node, index) => {
    const row = (index + 1) % 3 === 0 ? 2 : (index + 1) % 2 === 0 ? 1 : 0
    rows[row].push(node)
  })

  Array.from(new Array(3).keys()).map

  return (
    <Container ref={listRef}>
      {rows.map((row, rowIndex) => (
        <Column key={rowIndex}>
          {row.map((node) => {
            const Production = node.youtube ? ProductionVideo : ProductionImage
            return (
              <Item key={node.slug} id={node.slug} className={`grid-item`}>
                <Production node={node} />
              </Item>
            )
          })}
        </Column>
      ))}
    </Container>
  )
}

export default Gallery
