import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"

import Gallery from "src/components/Gallery"
import { richText } from "src/helpers/renderRichText"
import Layout from "src/layout"

const Description = styled.p`
  text-align: left;
  padding-top: 20px;
  max-width: 1200px;
  padding: 0 10px;
  margin: 0 auto;
  width: 100%;
`
const Link = styled.div`
  padding: 20px 0;
  font-size: 1.5em;
  text-align: center;
  text-transform: uppercase;
`
const GalleryContainer = styled.div`
  margin-top: 50px;
`

const CreationTemplate = ({ data, pageContext: { slug, productions } }) => {
  const creation = data.contentfulCreation

  return (
    <Layout title={creation.title}>
      <Description>{richText(creation.content)}</Description>
      {creation.dossier && (
        <Link>
          <a href={creation.dossier.file.url} target="_blank" rel="noreferrer">
            Télécharger le dossier
          </a>
        </Link>
      )}
      <GalleryContainer>
        <Gallery slug={slug} productions={productions} />
      </GalleryContainer>
    </Layout>
  )
}

export default CreationTemplate

export const pageQuery = graphql`
  query CreationById($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    contentfulCreation(id: { eq: $id }) {
      id
      title
      dossier {
        file {
          url
        }
      }
      # This is the rich text field
      content {
        raw
      }
    }
  }
`
