import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import styled from "styled-components"

import Modal from "./Modal"
import Tags from "./ProductionTags"

const Container = styled.div`
  position: relative;
  overflow: hidden;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;

  @media (max-width: 900px) {
    > div {
      left: 0px;
    }
  }

  &:hover {
    > div {
      left: 0px;
    }
  }
`

interface Props {
  node: Production
}

const ProductionImage = ({ node }: Props) => {
  const image = getImage(node.media as any)
  const full = getImage(node.full as any)
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal((previous) => !previous)
  }

  return (
    <>
      <Container onClick={toggleModal}>
        <GatsbyImage image={image} alt={node.title} />
        <Tags node={node} />
      </Container>
      {modal && (
        <Modal onClose={toggleModal}>
          <GatsbyImage image={full} alt={node.title} style={{ maxHeight: "100%" }} objectFit="contain" />
        </Modal>
      )}
    </>
  )
}

export default ProductionImage
