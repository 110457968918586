import React from "react"
import styled from "styled-components"

import Tags from "./ProductionTags"
import VideoPlayer from "./VideoPlayer"

const Container = styled.div`
  position: relative;
  overflow: hidden;
  padding: 5px;
  height: 480px;
  box-sizing: border-box;

  @media (max-width: 900px) {
    > div {
      left: 0px;
    }
  }

  &:hover {
    > div {
      left: 0px;
    }
  }
`

interface Props {
  node: Production
}

const ProductionVideo = ({ node }: Props) => {
  return (
    <Container>
      <VideoPlayer height={470} production={node} />
      <Tags node={node} />
    </Container>
  )
}

export default ProductionVideo
