import { FC, MouseEvent, useEffect } from "react"
import styled, { createGlobalStyle } from "styled-components"

import Portal from "src/components/Portal"

const GlobalStyle = createGlobalStyle`
  html {
    overflow: hidden;
    padding-right: 15px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(180, 180, 180, 0.9);
  z-index: 1;
`
const Box = styled.div<{ $maxWidth?: number }>`
  width: 100%;
  height: 100vh;
  border-radius: 16px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`
const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 32px;
  background: none;
  border: none;
`

export interface ModalProps {
  onClose: () => void
  maxWidth?: number
}

const Modal: FC<ModalProps> = ({ onClose, maxWidth, children }) => {
  useEffect(() => {
    const onKeyPress = ({ key }: KeyboardEvent) => {
      if (key === "Escape") {
        onClose()
      }
    }

    document.addEventListener("keydown", onKeyPress, false)
    return () => {
      document.removeEventListener("keydown", onKeyPress, false)
    }
  }, [onClose])

  const handleClose = (event: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    event.stopPropagation()
    onClose()
  }

  return (
    <Portal>
      <GlobalStyle />
      <Overlay onClick={handleClose}>
        <Box $maxWidth={maxWidth}>
          <CloseButton onClick={handleClose}>×</CloseButton>
          {children}
        </Box>
      </Overlay>
    </Portal>
  )
}

export default Modal
